<template>
    <b-container fluid>
       <b-row>
          <b-col sm="12">
               <card>
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Bootstrap Datatables</h4>
                  </template>
                  <template v-slot:headerAction>
                     <i class="ri-code-s-slash-line" v-b-toggle.collapse-1>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                        </svg>
                     </i>
                  </template>  
                  <template v-slot:body>
                     <b-collapse id="collapse-1" class="mb-2">
                     <div class="card">
                        <kbd class="bg-dark">
                           <pre class="text-white" id="buttons-dropdown">
                              <code>
&lt;table id=&quot;datatable&quot; class=&quot;table data-table table-striped table-bordered&quot; &gt;
   &lt;thead&gt;
      &lt;tr&gt;
         &lt;th&gt;Name&lt;/th&gt;
         &lt;th&gt;Position&lt;/th&gt;
         &lt;th&gt;Office&lt;/th&gt;
         &lt;th&gt;Age&lt;/th&gt;
         &lt;th&gt;Start date&lt;/th&gt;
         &lt;th&gt;Salary&lt;/th&gt;
      &lt;/tr&gt;
   &lt;/thead&gt;
   &lt;tbody&gt;
      &lt;tr&gt;
         &lt;td&gt;Tiger Nixon&lt;/td&gt;
         &lt;td&gt;System Architect&lt;/td&gt;
         &lt;td&gt;Edinburgh&lt;/td&gt;
         &lt;td&gt;61&lt;/td&gt;
         &lt;td&gt;2011/04/25&lt;/td&gt;
         &lt;td&gt;$320,800&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Garrett Winters&lt;/td&gt;
         &lt;td&gt;Accountant&lt;/td&gt;
         &lt;td&gt;Tokyo&lt;/td&gt;
         &lt;td&gt;63&lt;/td&gt;
         &lt;td&gt;2011/07/25&lt;/td&gt;
         &lt;td&gt;$170,750&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Ashton Cox&lt;/td&gt;
         &lt;td&gt;Junior Technical Author&lt;/td&gt;
         &lt;td&gt;San Francisco&lt;/td&gt;
         &lt;td&gt;66&lt;/td&gt;
         &lt;td&gt;2009/01/12&lt;/td&gt;
         &lt;td&gt;$86,000&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Cedric Kelly&lt;/td&gt;
         &lt;td&gt;Senior Javascript Developer&lt;/td&gt;
         &lt;td&gt;Edinburgh&lt;/td&gt;
         &lt;td&gt;22&lt;/td&gt;
         &lt;td&gt;2012/03/29&lt;/td&gt;
         &lt;td&gt;$433,060&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Airi Satou&lt;/td&gt;
         &lt;td&gt;Accountant&lt;/td&gt;
         &lt;td&gt;Tokyo&lt;/td&gt;
         &lt;td&gt;33&lt;/td&gt;
         &lt;td&gt;2008/11/28&lt;/td&gt;
         &lt;td&gt;$162,700&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Brielle Williamson&lt;/td&gt;
         &lt;td&gt;Integration Specialist&lt;/td&gt;
         &lt;td&gt;New York&lt;/td&gt;
         &lt;td&gt;61&lt;/td&gt;
         &lt;td&gt;2012/12/02&lt;/td&gt;
         &lt;td&gt;$372,000&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Herrod Chandler&lt;/td&gt;
         &lt;td&gt;Sales Assistant&lt;/td&gt;
         &lt;td&gt;San Francisco&lt;/td&gt;
         &lt;td&gt;59&lt;/td&gt;
         &lt;td&gt;2012/08/06&lt;/td&gt;
         &lt;td&gt;$137,500&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Rhona Davidson&lt;/td&gt;
         &lt;td&gt;Integration Specialist&lt;/td&gt;
         &lt;td&gt;Tokyo&lt;/td&gt;
         &lt;td&gt;55&lt;/td&gt;
         &lt;td&gt;2010/10/14&lt;/td&gt;
         &lt;td&gt;$327,900&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Colleen Hurst&lt;/td&gt;
         &lt;td&gt;Javascript Developer&lt;/td&gt;
         &lt;td&gt;San Francisco&lt;/td&gt;
         &lt;td&gt;39&lt;/td&gt;
         &lt;td&gt;2009/09/15&lt;/td&gt;
         &lt;td&gt;$205,500&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Sonya Frost&lt;/td&gt;
         &lt;td&gt;Software Engineer&lt;/td&gt;
         &lt;td&gt;Edinburgh&lt;/td&gt;
         &lt;td&gt;23&lt;/td&gt;
         &lt;td&gt;2008/12/13&lt;/td&gt;
         &lt;td&gt;$103,600&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Jena Gaines&lt;/td&gt;
         &lt;td&gt;Office Manager&lt;/td&gt;
         &lt;td&gt;London&lt;/td&gt;
         &lt;td&gt;30&lt;/td&gt;
         &lt;td&gt;2008/12/19&lt;/td&gt;
         &lt;td&gt;$90,560&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Quinn Flynn&lt;/td&gt;
         &lt;td&gt;Support Lead&lt;/td&gt;
         &lt;td&gt;Edinburgh&lt;/td&gt;
         &lt;td&gt;22&lt;/td&gt;
         &lt;td&gt;2013/03/03&lt;/td&gt;
         &lt;td&gt;$342,000&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Charde Marshall&lt;/td&gt;
         &lt;td&gt;Regional Director&lt;/td&gt;
         &lt;td&gt;San Francisco&lt;/td&gt;
         &lt;td&gt;36&lt;/td&gt;
         &lt;td&gt;2008/10/16&lt;/td&gt;
         &lt;td&gt;$470,600&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Haley Kennedy&lt;/td&gt;
         &lt;td&gt;Senior Marketing Designer&lt;/td&gt;
         &lt;td&gt;London&lt;/td&gt;
         &lt;td&gt;43&lt;/td&gt;
         &lt;td&gt;2012/12/18&lt;/td&gt;
         &lt;td&gt;$313,500&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Tatyana Fitzpatrick&lt;/td&gt;
         &lt;td&gt;Regional Director&lt;/td&gt;
         &lt;td&gt;London&lt;/td&gt;
         &lt;td&gt;19&lt;/td&gt;
         &lt;td&gt;2010/03/17&lt;/td&gt;
         &lt;td&gt;$385,750&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Michael Silva&lt;/td&gt;
         &lt;td&gt;Marketing Designer&lt;/td&gt;
         &lt;td&gt;London&lt;/td&gt;
         &lt;td&gt;66&lt;/td&gt;
         &lt;td&gt;2012/11/27&lt;/td&gt;
         &lt;td&gt;$198,500&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Paul Byrd&lt;/td&gt;
         &lt;td&gt;Chief Financial Officer (CFO)&lt;/td&gt;
         &lt;td&gt;New York&lt;/td&gt;
         &lt;td&gt;64&lt;/td&gt;
         &lt;td&gt;2010/06/09&lt;/td&gt;
         &lt;td&gt;$725,000&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Gloria Little&lt;/td&gt;
         &lt;td&gt;Systems Administrator&lt;/td&gt;
         &lt;td&gt;New York&lt;/td&gt;
         &lt;td&gt;59&lt;/td&gt;
         &lt;td&gt;2009/04/10&lt;/td&gt;
         &lt;td&gt;$237,500&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Bradley Greer&lt;/td&gt;
         &lt;td&gt;Software Engineer&lt;/td&gt;
         &lt;td&gt;London&lt;/td&gt;
         &lt;td&gt;41&lt;/td&gt;
         &lt;td&gt;2012/10/13&lt;/td&gt;
         &lt;td&gt;$132,000&lt;/td&gt;
      &lt;/tr&gt;
      &lt;tr&gt;
         &lt;td&gt;Dai Rios&lt;/td&gt;
         &lt;td&gt;Personnel Lead&lt;/td&gt;
         &lt;td&gt;Edinburgh&lt;/td&gt;
         &lt;td&gt;35&lt;/td&gt;
         &lt;td&gt;2012/09/26&lt;/td&gt;
         &lt;td&gt;$217,500&lt;/td&gt;
      &lt;/tr&gt;
&lt;/table&gt;
                              </code>
                           </pre>
                        </kbd>
                     </div>
                  </b-collapse>
                     <p>Images in Bootstrap are made responsive with <code>.img-fluid</code>. <code>max-width: 100%;</code> and <code>height: auto;</code> are applied to the image so that it scales with the parent element.</p>
                     <div class="table-responsive">
                        <table id="datatable" class="table data-table table-striped table-bordered dataTable" >
                           <thead>
                              <tr>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th>Salary</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>Tiger Nixon</td>
                                 <td>System Architect</td>
                                 <td>Edinburgh</td>
                                 <td>61</td>
                                 <td>2011/04/25</td>
                                 <td>$320,800</td>
                              </tr>
                              <tr>
                                 <td>Garrett Winters</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>63</td>
                                 <td>2011/07/25</td>
                                 <td>$170,750</td>
                              </tr>
                              <tr>
                                 <td>Ashton Cox</td>
                                 <td>Junior Technical Author</td>
                                 <td>San Francisco</td>
                                 <td>66</td>
                                 <td>2009/01/12</td>
                                 <td>$86,000</td>
                              </tr>
                              <tr>
                                 <td>Cedric Kelly</td>
                                 <td>Senior Javascript Developer</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2012/03/29</td>
                                 <td>$433,060</td>
                              </tr>
                              <tr>
                                 <td>Airi Satou</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>33</td>
                                 <td>2008/11/28</td>
                                 <td>$162,700</td>
                              </tr>
                              <tr>
                                 <td>Brielle Williamson</td>
                                 <td>Integration Specialist</td>
                                 <td>New York</td>
                                 <td>61</td>
                                 <td>2012/12/02</td>
                                 <td>$372,000</td>
                              </tr>
                              <tr>
                                 <td>Herrod Chandler</td>
                                 <td>Sales Assistant</td>
                                 <td>San Francisco</td>
                                 <td>59</td>
                                 <td>2012/08/06</td>
                                 <td>$137,500</td>
                              </tr>
                              <tr>
                                 <td>Rhona Davidson</td>
                                 <td>Integration Specialist</td>
                                 <td>Tokyo</td>
                                 <td>55</td>
                                 <td>2010/10/14</td>
                                 <td>$327,900</td>
                              </tr>
                              <tr>
                                 <td>Colleen Hurst</td>
                                 <td>Javascript Developer</td>
                                 <td>San Francisco</td>
                                 <td>39</td>
                                 <td>2009/09/15</td>
                                 <td>$205,500</td>
                              </tr>
                              <tr>
                                 <td>Sonya Frost</td>
                                 <td>Software Engineer</td>
                                 <td>Edinburgh</td>
                                 <td>23</td>
                                 <td>2008/12/13</td>
                                 <td>$103,600</td>
                              </tr>
                              <tr>
                                 <td>Jena Gaines</td>
                                 <td>Office Manager</td>
                                 <td>London</td>
                                 <td>30</td>
                                 <td>2008/12/19</td>
                                 <td>$90,560</td>
                              </tr>
                              <tr>
                                 <td>Quinn Flynn</td>
                                 <td>Support Lead</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2013/03/03</td>
                                 <td>$342,000</td>
                              </tr>
                              <tr>
                                 <td>Charde Marshall</td>
                                 <td>Regional Director</td>
                                 <td>San Francisco</td>
                                 <td>36</td>
                                 <td>2008/10/16</td>
                                 <td>$470,600</td>
                              </tr>
                              <tr>
                                 <td>Haley Kennedy</td>
                                 <td>Senior Marketing Designer</td>
                                 <td>London</td>
                                 <td>43</td>
                                 <td>2012/12/18</td>
                                 <td>$313,500</td>
                              </tr>
                              <tr>
                                 <td>Tatyana Fitzpatrick</td>
                                 <td>Regional Director</td>
                                 <td>London</td>
                                 <td>19</td>
                                 <td>2010/03/17</td>
                                 <td>$385,750</td>
                              </tr>
                              <tr>
                                 <td>Michael Silva</td>
                                 <td>Marketing Designer</td>
                                 <td>London</td>
                                 <td>66</td>
                                 <td>2012/11/27</td>
                                 <td>$198,500</td>
                              </tr>
                              <tr>
                                 <td>Paul Byrd</td>
                                 <td>Chief Financial Officer (CFO)</td>
                                 <td>New York</td>
                                 <td>64</td>
                                 <td>2010/06/09</td>
                                 <td>$725,000</td>
                              </tr>
                              <tr>
                                 <td>Gloria Little</td>
                                 <td>Systems Administrator</td>
                                 <td>New York</td>
                                 <td>59</td>
                                 <td>2009/04/10</td>
                                 <td>$237,500</td>
                              </tr>
                              <tr>
                                 <td>Bradley Greer</td>
                                 <td>Software Engineer</td>
                                 <td>London</td>
                                 <td>41</td>
                                 <td>2012/10/13</td>
                                 <td>$132,000</td>
                              </tr>
                              <tr>
                                 <td>Dai Rios</td>
                                 <td>Personnel Lead</td>
                                 <td>Edinburgh</td>
                                 <td>35</td>
                                 <td>2012/09/26</td>
                                 <td>$217,500</td>
                              </tr>
                              <tr>
                                 <td>Jenette Caldwell</td>
                                 <td>Development Lead</td>
                                 <td>New York</td>
                                 <td>30</td>
                                 <td>2011/09/03</td>
                                 <td>$345,000</td>
                              </tr>
                              <tr>
                                 <td>Yuri Berry</td>
                                 <td>Chief Marketing Officer (CMO)</td>
                                 <td>New York</td>
                                 <td>40</td>
                                 <td>2009/06/25</td>
                                 <td>$675,000</td>
                              </tr>
                              <tr>
                                 <td>Caesar Vance</td>
                                 <td>Pre-Sales Support</td>
                                 <td>New York</td>
                                 <td>21</td>
                                 <td>2011/12/12</td>
                                 <td>$106,450</td>
                              </tr>
                              <tr>
                                 <td>Doris Wilder</td>
                                 <td>Sales Assistant</td>
                                 <td>Sydney</td>
                                 <td>23</td>
                                 <td>2010/09/20</td>
                                 <td>$85,600</td>
                              </tr>
                              <tr>
                                 <td>Angelica Ramos</td>
                                 <td>Chief Executive Officer (CEO)</td>
                                 <td>London</td>
                                 <td>47</td>
                                 <td>2009/10/09</td>
                                 <td>$1,200,000</td>
                              </tr>
                              <tr>
                                 <td>Gavin Joyce</td>
                                 <td>Developer</td>
                                 <td>Edinburgh</td>
                                 <td>42</td>
                                 <td>2010/12/22</td>
                                 <td>$92,575</td>
                              </tr>
                              <tr>
                                 <td>Jennifer Chang</td>
                                 <td>Regional Director</td>
                                 <td>Singapore</td>
                                 <td>28</td>
                                 <td>2010/11/14</td>
                                 <td>$357,650</td>
                              </tr>
                              <tr>
                                 <td>Brenden Wagner</td>
                                 <td>Software Engineer</td>
                                 <td>San Francisco</td>
                                 <td>28</td>
                                 <td>2011/06/07</td>
                                 <td>$206,850</td>
                              </tr>
                              <tr>
                                 <td>Fiona Green</td>
                                 <td>Chief Operating Officer (COO)</td>
                                 <td>San Francisco</td>
                                 <td>48</td>
                                 <td>2010/03/11</td>
                                 <td>$850,000</td>
                              </tr>
                              <tr>
                                 <td>Shou Itou</td>
                                 <td>Regional Marketing</td>
                                 <td>Tokyo</td>
                                 <td>20</td>
                                 <td>2011/08/14</td>
                                 <td>$163,000</td>
                              </tr>
                              <tr>
                                 <td>Michelle House</td>
                                 <td>Integration Specialist</td>
                                 <td>Sydney</td>
                                 <td>37</td>
                                 <td>2011/06/02</td>
                                 <td>$95,400</td>
                              </tr>
                              <tr>
                                 <td>Suki Burks</td>
                                 <td>Developer</td>
                                 <td>London</td>
                                 <td>53</td>
                                 <td>2009/10/22</td>
                                 <td>$114,500</td>
                              </tr>
                              <tr>
                                 <td>Prescott Bartlett</td>
                                 <td>Technical Author</td>
                                 <td>London</td>
                                 <td>27</td>
                                 <td>2011/05/07</td>
                                 <td>$145,000</td>
                              </tr>
                              <tr>
                                 <td>Gavin Cortez</td>
                                 <td>Team Leader</td>
                                 <td>San Francisco</td>
                                 <td>22</td>
                                 <td>2008/10/26</td>
                                 <td>$235,500</td>
                              </tr>
                              <tr>
                                 <td>Martena Mccray</td>
                                 <td>Post-Sales support</td>
                                 <td>Edinburgh</td>
                                 <td>46</td>
                                 <td>2011/03/09</td>
                                 <td>$324,050</td>
                              </tr>
                              <tr>
                                 <td>Unity Butler</td>
                                 <td>Marketing Designer</td>
                                 <td>San Francisco</td>
                                 <td>47</td>
                                 <td>2009/12/09</td>
                                 <td>$85,675</td>
                              </tr>
                              <tr>
                                 <td>Howard Hatfield</td>
                                 <td>Office Manager</td>
                                 <td>San Francisco</td>
                                 <td>51</td>
                                 <td>2008/12/16</td>
                                 <td>$164,500</td>
                              </tr>
                              <tr>
                                 <td>Hope Fuentes</td>
                                 <td>Secretary</td>
                                 <td>San Francisco</td>
                                 <td>41</td>
                                 <td>2010/02/12</td>
                                 <td>$109,850</td>
                              </tr>
                              <tr>
                                 <td>Vivian Harrell</td>
                                 <td>Financial Controller</td>
                                 <td>San Francisco</td>
                                 <td>62</td>
                                 <td>2009/02/14</td>
                                 <td>$452,500</td>
                              </tr>
                              <tr>
                                 <td>Timothy Mooney</td>
                                 <td>Office Manager</td>
                                 <td>London</td>
                                 <td>37</td>
                                 <td>2008/12/11</td>
                                 <td>$136,200</td>
                              </tr>
                              <tr>
                                 <td>Jackson Bradshaw</td>
                                 <td>Director</td>
                                 <td>New York</td>
                                 <td>65</td>
                                 <td>2008/09/26</td>
                                 <td>$645,750</td>
                              </tr>
                              <tr>
                                 <td>Olivia Liang</td>
                                 <td>Support Engineer</td>
                                 <td>Singapore</td>
                                 <td>64</td>
                                 <td>2011/02/03</td>
                                 <td>$234,500</td>
                              </tr>
                              <tr>
                                 <td>Bruno Nash</td>
                                 <td>Software Engineer</td>
                                 <td>London</td>
                                 <td>38</td>
                                 <td>2011/05/03</td>
                                 <td>$163,500</td>
                              </tr>
                              <tr>
                                 <td>Sakura Yamamoto</td>
                                 <td>Support Engineer</td>
                                 <td>Tokyo</td>
                                 <td>37</td>
                                 <td>2009/08/19</td>
                                 <td>$139,575</td>
                              </tr>
                              <tr>
                                 <td>Thor Walton</td>
                                 <td>Developer</td>
                                 <td>New York</td>
                                 <td>61</td>
                                 <td>2013/08/11</td>
                                 <td>$98,540</td>
                              </tr>
                              <tr>
                                 <td>Finn Camacho</td>
                                 <td>Support Engineer</td>
                                 <td>San Francisco</td>
                                 <td>47</td>
                                 <td>2009/07/07</td>
                                 <td>$87,500</td>
                              </tr>
                              <tr>
                                 <td>Serge Baldwin</td>
                                 <td>Data Coordinator</td>
                                 <td>Singapore</td>
                                 <td>64</td>
                                 <td>2012/04/09</td>
                                 <td>$138,575</td>
                              </tr>
                              <tr>
                                 <td>Zenaida Frank</td>
                                 <td>Software Engineer</td>
                                 <td>New York</td>
                                 <td>63</td>
                                 <td>2010/01/04</td>
                                 <td>$125,250</td>
                              </tr>
                              <tr>
                                 <td>Zorita Serrano</td>
                                 <td>Software Engineer</td>
                                 <td>San Francisco</td>
                                 <td>56</td>
                                 <td>2012/06/01</td>
                                 <td>$115,000</td>
                              </tr>
                              <tr>
                                 <td>Jennifer Acosta</td>
                                 <td>Junior Javascript Developer</td>
                                 <td>Edinburgh</td>
                                 <td>43</td>
                                 <td>2013/02/01</td>
                                 <td>$75,650</td>
                              </tr>
                              <tr>
                                 <td>Cara Stevens</td>
                                 <td>Sales Assistant</td>
                                 <td>New York</td>
                                 <td>46</td>
                                 <td>2011/12/06</td>
                                 <td>$145,600</td>
                              </tr>
                              <tr>
                                 <td>Hermione Butler</td>
                                 <td>Regional Director</td>
                                 <td>London</td>
                                 <td>47</td>
                                 <td>2011/03/21</td>
                                 <td>$356,250</td>
                              </tr>
                              <tr>
                                 <td>Lael Greer</td>
                                 <td>Systems Administrator</td>
                                 <td>London</td>
                                 <td>21</td>
                                 <td>2009/02/27</td>
                                 <td>$103,500</td>
                              </tr>
                              <tr>
                                 <td>Jonas Alexander</td>
                                 <td>Developer</td>
                                 <td>San Francisco</td>
                                 <td>30</td>
                                 <td>2010/07/14</td>
                                 <td>$86,500</td>
                              </tr>
                              <tr>
                                 <td>Shad Decker</td>
                                 <td>Regional Director</td>
                                 <td>Edinburgh</td>
                                 <td>51</td>
                                 <td>2008/11/13</td>
                                 <td>$183,000</td>
                              </tr>
                              <tr>
                                 <td>Michael Bruce</td>
                                 <td>Javascript Developer</td>
                                 <td>Singapore</td>
                                 <td>29</td>
                                 <td>2011/06/27</td>
                                 <td>$183,000</td>
                              </tr>
                              <tr>
                                 <td>Donna Snider</td>
                                 <td>Customer Support</td>
                                 <td>New York</td>
                                 <td>27</td>
                                 <td>2011/01/25</td>
                                 <td>$112,000</td>
                              </tr>
                           </tbody>
                           <tfoot>
                              <tr>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th>Salary</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </template>
               </card>
          </b-col>
          <b-col sm="12">
               <card>
                  <template v-slot:headerTitle>
                        <h4 class="card-title">Bootstrap Datatables With Checkbox</h4>
                  </template>
                  <template v-slot:headerAction>
                     <i class="ri-code-s-slash-line" v-b-toggle.collapse-2>
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                        </svg>
                     </i>
                  </template>  
                  <template v-slot:body>
                      <b-collapse id="collapse-2" class="mb-2">
                     <div class="card">
                        <kbd class="bg-dark">
                           <pre class="text-white" id="buttons-dropdown">
                              <code>
&lt;table id=&quot;datatable&quot; class=&quot;table data-table table-striped table-bordered&quot; &gt;
&lt;thead&gt;
   &lt;tr&gt;
      &lt;th&gt;Name&lt;/th&gt;
      &lt;th&gt;Position&lt;/th&gt;
      &lt;th&gt;Office&lt;/th&gt;
      &lt;th&gt;Age&lt;/th&gt;
      &lt;th&gt;Start date&lt;/th&gt;
      &lt;th&gt;Salary&lt;/th&gt;
   &lt;/tr&gt;
&lt;/thead&gt;
&lt;tbody&gt;
   &lt;tr&gt;
      &lt;td&gt;Tiger Nixon&lt;/td&gt;
      &lt;td&gt;System Architect&lt;/td&gt;
      &lt;td&gt;Edinburgh&lt;/td&gt;
      &lt;td&gt;61&lt;/td&gt;
      &lt;td&gt;2011/04/25&lt;/td&gt;
      &lt;td&gt;$320,800&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Garrett Winters&lt;/td&gt;
      &lt;td&gt;Accountant&lt;/td&gt;
      &lt;td&gt;Tokyo&lt;/td&gt;
      &lt;td&gt;63&lt;/td&gt;
      &lt;td&gt;2011/07/25&lt;/td&gt;
      &lt;td&gt;$170,750&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Ashton Cox&lt;/td&gt;
      &lt;td&gt;Junior Technical Author&lt;/td&gt;
      &lt;td&gt;San Francisco&lt;/td&gt;
      &lt;td&gt;66&lt;/td&gt;
      &lt;td&gt;2009/01/12&lt;/td&gt;
      &lt;td&gt;$86,000&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Cedric Kelly&lt;/td&gt;
      &lt;td&gt;Senior Javascript Developer&lt;/td&gt;
      &lt;td&gt;Edinburgh&lt;/td&gt;
      &lt;td&gt;22&lt;/td&gt;
      &lt;td&gt;2012/03/29&lt;/td&gt;
      &lt;td&gt;$433,060&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Airi Satou&lt;/td&gt;
      &lt;td&gt;Accountant&lt;/td&gt;
      &lt;td&gt;Tokyo&lt;/td&gt;
      &lt;td&gt;33&lt;/td&gt;
      &lt;td&gt;2008/11/28&lt;/td&gt;
      &lt;td&gt;$162,700&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Brielle Williamson&lt;/td&gt;
      &lt;td&gt;Integration Specialist&lt;/td&gt;
      &lt;td&gt;New York&lt;/td&gt;
      &lt;td&gt;61&lt;/td&gt;
      &lt;td&gt;2012/12/02&lt;/td&gt;
      &lt;td&gt;$372,000&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Herrod Chandler&lt;/td&gt;
      &lt;td&gt;Sales Assistant&lt;/td&gt;
      &lt;td&gt;San Francisco&lt;/td&gt;
      &lt;td&gt;59&lt;/td&gt;
      &lt;td&gt;2012/08/06&lt;/td&gt;
      &lt;td&gt;$137,500&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Rhona Davidson&lt;/td&gt;
      &lt;td&gt;Integration Specialist&lt;/td&gt;
      &lt;td&gt;Tokyo&lt;/td&gt;
      &lt;td&gt;55&lt;/td&gt;
      &lt;td&gt;2010/10/14&lt;/td&gt;
      &lt;td&gt;$327,900&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Colleen Hurst&lt;/td&gt;
      &lt;td&gt;Javascript Developer&lt;/td&gt;
      &lt;td&gt;San Francisco&lt;/td&gt;
      &lt;td&gt;39&lt;/td&gt;
      &lt;td&gt;2009/09/15&lt;/td&gt;
      &lt;td&gt;$205,500&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Sonya Frost&lt;/td&gt;
      &lt;td&gt;Software Engineer&lt;/td&gt;
      &lt;td&gt;Edinburgh&lt;/td&gt;
      &lt;td&gt;23&lt;/td&gt;
      &lt;td&gt;2008/12/13&lt;/td&gt;
      &lt;td&gt;$103,600&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Jena Gaines&lt;/td&gt;
      &lt;td&gt;Office Manager&lt;/td&gt;
      &lt;td&gt;London&lt;/td&gt;
      &lt;td&gt;30&lt;/td&gt;
      &lt;td&gt;2008/12/19&lt;/td&gt;
      &lt;td&gt;$90,560&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Quinn Flynn&lt;/td&gt;
      &lt;td&gt;Support Lead&lt;/td&gt;
      &lt;td&gt;Edinburgh&lt;/td&gt;
      &lt;td&gt;22&lt;/td&gt;
      &lt;td&gt;2013/03/03&lt;/td&gt;
      &lt;td&gt;$342,000&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Charde Marshall&lt;/td&gt;
      &lt;td&gt;Regional Director&lt;/td&gt;
      &lt;td&gt;San Francisco&lt;/td&gt;
      &lt;td&gt;36&lt;/td&gt;
      &lt;td&gt;2008/10/16&lt;/td&gt;
      &lt;td&gt;$470,600&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Haley Kennedy&lt;/td&gt;
      &lt;td&gt;Senior Marketing Designer&lt;/td&gt;
      &lt;td&gt;London&lt;/td&gt;
      &lt;td&gt;43&lt;/td&gt;
      &lt;td&gt;2012/12/18&lt;/td&gt;
      &lt;td&gt;$313,500&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Tatyana Fitzpatrick&lt;/td&gt;
      &lt;td&gt;Regional Director&lt;/td&gt;
      &lt;td&gt;London&lt;/td&gt;
      &lt;td&gt;19&lt;/td&gt;
      &lt;td&gt;2010/03/17&lt;/td&gt;
      &lt;td&gt;$385,750&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Michael Silva&lt;/td&gt;
      &lt;td&gt;Marketing Designer&lt;/td&gt;
      &lt;td&gt;London&lt;/td&gt;
      &lt;td&gt;66&lt;/td&gt;
      &lt;td&gt;2012/11/27&lt;/td&gt;
      &lt;td&gt;$198,500&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Paul Byrd&lt;/td&gt;
      &lt;td&gt;Chief Financial Officer (CFO)&lt;/td&gt;
      &lt;td&gt;New York&lt;/td&gt;
      &lt;td&gt;64&lt;/td&gt;
      &lt;td&gt;2010/06/09&lt;/td&gt;
      &lt;td&gt;$725,000&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Gloria Little&lt;/td&gt;
      &lt;td&gt;Systems Administrator&lt;/td&gt;
      &lt;td&gt;New York&lt;/td&gt;
      &lt;td&gt;59&lt;/td&gt;
      &lt;td&gt;2009/04/10&lt;/td&gt;
      &lt;td&gt;$237,500&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Bradley Greer&lt;/td&gt;
      &lt;td&gt;Software Engineer&lt;/td&gt;
      &lt;td&gt;London&lt;/td&gt;
      &lt;td&gt;41&lt;/td&gt;
      &lt;td&gt;2012/10/13&lt;/td&gt;
      &lt;td&gt;$132,000&lt;/td&gt;
   &lt;/tr&gt;
   &lt;tr&gt;
      &lt;td&gt;Dai Rios&lt;/td&gt;
      &lt;td&gt;Personnel Lead&lt;/td&gt;
      &lt;td&gt;Edinburgh&lt;/td&gt;
      &lt;td&gt;35&lt;/td&gt;
      &lt;td&gt;2012/09/26&lt;/td&gt;
      &lt;td&gt;$217,500&lt;/td&gt;
   &lt;/tr&gt;
&lt;/table&gt;            
                              </code>
                           </pre>
                        </kbd>
                     </div>
                  </b-collapse>
                     <p>Images in Bootstrap are made responsive with <code>.img-fluid</code>. <code>max-width: 100%;</code> and <code>height: auto;</code> are applied to the image so that it scales with the parent element.</p>
                     <div class="table-responsive">
                        <table id="datatable" class="table data-table table-striped table-bordered" >
                           <thead>
                              <tr>
                                 <th scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck1">
                                          <label class="custom-control-label" for="customCheck1"></label>
                                       </div>
                                    </div>
                                 </th>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th class="text-right">Salary</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck">
                                          <label class="custom-control-label" for="customCheck"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Tiger Nixon</td>
                                 <td>System Architect</td>
                                 <td>Edinburgh</td>
                                 <td>61</td>
                                 <td>2011/04/25</td>
                                 <td class="text-right">$320,800</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck2">
                                          <label class="custom-control-label" for="customCheck2"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Garrett Winters</td>
                                 <td>Accountant</td>
                                 <td>Tokyo</td>
                                 <td>63</td>
                                 <td>2011/07/25</td>
                                 <td class="text-right">$170,750</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck3">
                                          <label class="custom-control-label" for="customCheck3"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Herrod Chandler</td>
                                 <td>Sales Assistant</td>
                                 <td>San Francisco</td>
                                 <td>59</td>
                                 <td>2012/08/06</td>
                                 <td class="text-right">$137,500</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck4">
                                          <label class="custom-control-label" for="customCheck4"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Rhona Davidson</td>
                                 <td>Integration Specialist</td>
                                 <td>Tokyo</td>
                                 <td>55</td>
                                 <td>2010/10/14</td>
                                 <td class="text-right">$327,900</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck5">
                                          <label class="custom-control-label" for="customCheck5"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Colleen Hurst</td>
                                 <td>Javascript Developer</td>
                                 <td>San Francisco</td>
                                 <td>39</td>
                                 <td>2009/09/15</td>
                                 <td class="text-right">$205,500</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck6">
                                          <label class="custom-control-label" for="customCheck6"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Sonya Frost</td>
                                 <td>Software Engineer</td>
                                 <td>Edinburgh</td>
                                 <td>23</td>
                                 <td>2008/12/13</td>
                                 <td class="text-right">$103,600</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck11">
                                          <label class="custom-control-label" for="customCheck11"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Jena Gaines</td>
                                 <td>Office Manager</td>
                                 <td>London</td>
                                 <td>30</td>
                                 <td>2008/12/19</td>
                                 <td class="text-right">$90,560</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck7">
                                          <label class="custom-control-label" for="customCheck7"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Quinn Flynn</td>
                                 <td>Support Lead</td>
                                 <td>Edinburgh</td>
                                 <td>22</td>
                                 <td>2013/03/03</td>
                                 <td class="text-right">$342,000</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck8">
                                          <label class="custom-control-label" for="customCheck8"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Charde Marshall</td>
                                 <td>Regional Director</td>
                                 <td>San Francisco</td>
                                 <td>36</td>
                                 <td>2008/10/16</td>
                                 <td class="text-right">$470,600</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck9">
                                          <label class="custom-control-label" for="customCheck9"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Haley Kennedy</td>
                                 <td>Senior Marketing Designer</td>
                                 <td>London</td>
                                 <td>43</td>
                                 <td>2012/12/18</td>
                                 <td class="text-right">$313,500</td>
                              </tr>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1 ">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck10">
                                          <label class="custom-control-label" for="customCheck10"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <td>Tatyana Fitzpatrick</td>
                                 <td>Regional Director</td>
                                 <td>London</td>
                                 <td>19</td>
                                 <td>2010/03/17</td>
                                 <td class="text-right">$385,750</td>
                              </tr>
                           </tbody>
                           <tfoot>
                              <tr>
                                 <td scope="col" class="pr-0">
                                    <div class="d-flex justify-content-start align-items-end mb-1">
                                       <div class="custom-control custom-checkbox custom-control-inline">
                                          <input type="checkbox" class="custom-control-input m-0" id="customCheck100">
                                          <label class="custom-control-label" for="customCheck100"></label>
                                       </div>
                                    </div>
                                 </td>
                                 <th>Name</th>
                                 <th>Position</th>
                                 <th>Office</th>
                                 <th>Age</th>
                                 <th>Start date</th>
                                 <th class="text-right">Salary</th>
                              </tr>
                           </tfoot>
                        </table>
                     </div>
                  </template>
               </card>
          </b-col>
       </b-row>
    </b-container>
</template>
<script>
export default {
   name: 'DataTable',
   mounted() {
      //window.$('#datatable').DataTable()
   }
}
</script>